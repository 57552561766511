import { computed, ref } from 'vue';
import { type MaybeRef, toRef, toValue, useDebounceFn } from '@vueuse/core';
import { useInfiniteQuery, useQueryClient } from '@tanstack/vue-query';
import { get } from 'lodash-es';

export function useAsyncSelectNextOptions<
  T extends (...args: any[]) => any,
  K extends (params: any) => any,
>({
  requestFn,
  mapOptionFn = undefined,
  params = undefined,
  queryKey,
  dataItemsKey = 'results',
  searchDelay = 300,
  pageLimit = 50,
  isEnabled = true,
}: {
  requestFn: T;
  mapOptionFn?: K;
  params?: MaybeRef<Partial<Parameters<T>[0]>>;
  queryKey: string;
  dataItemsKey?: string;
  searchDelay?: number;
  pageLimit?: number;
  isEnabled?: MaybeRef<boolean>;
}) {
  const searchForRequest = ref('');
  const searchValue = ref('');

  const updateSearchForParamsWithDebounce = useDebounceFn((value: string) => {
    searchForRequest.value = value;
  }, searchDelay);

  const updateSearchValue = (value: string) => {
    searchValue.value = value;
    updateSearchForParamsWithDebounce(value);
  };

  /**
   * @todo добавить Signal для работы аборт контроллера
   */
  const queryFn = async ({ pageParam = 1 }) => {
    const data = await requestFn({
      page: pageParam,
      search: searchForRequest.value || undefined,
      ...toValue(params),
    });
    return get(data, dataItemsKey);
  };
  const getQueryKey = () => [queryKey, params, searchForRequest];
  const queryClient = useQueryClient();

  const clearSavedState = () => {
    const state = queryClient.getQueryState(getQueryKey());
    if (state && toValue(isEnabled)) {
      queryClient.setQueryData(
        getQueryKey(),
        (data?: { pageParams: number[]; pages: any[] }) => {
          return {
            pages: data?.pages?.length ? data.pages.slice(0, 1) : [],
            pageParams: data?.pageParams?.length
              ? data.pageParams.slice(0, 1)
              : [],
          };
        },
      );
    }
  };

  clearSavedState();

  const refetch = () => {
    clearSavedState();
    queryClient.invalidateQueries({ queryKey: getQueryKey() });
  };

  const {
    data,
    hasNextPage,
    isPending: isLoading,
    isFetchingNextPage: isFetching,
    fetchNextPage: loadNextPage,
  } = useInfiniteQuery({
    enabled: toRef(isEnabled),
    queryKey: getQueryKey(),
    queryFn,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0 || lastPage.length < pageLimit) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    initialPageParam: 1,
  });

  const items = computed<ReturnType<T>[]>(() => {
    if (!data.value?.pages?.length) return [];
    return data.value.pages.reduce((acc, page) => [...acc, ...page], []);
  });

  const options = computed<ReturnType<K>[]>(() => {
    if (!items.value.length) return [];
    if (!mapOptionFn) return items.value;

    return items.value.map(mapOptionFn);
  });

  return {
    items,
    options,
    searchValue,
    searchForRequest,

    hasNextPage,
    isFetching,
    isLoading,

    loadNextPage,
    updateSearchValue,
    refetch,
  };
}
