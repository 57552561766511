import { UiColorVar } from '@karta.io/ui-components';

import {
  CoreOrganizationsSortType,
  CorePhoneVerificationMethod,
  CoreUserPermissionKey,
  PermissionAction,
} from '@/enums';

/**
 * @description COMPANY
 */
const CoreUserCompanyPermissionsMap = {
  ReadCompany: {
    key: CoreUserPermissionKey.Company,
    action: PermissionAction.Read,
  },
  ReadCompanyUser: {
    key: CoreUserPermissionKey.CompanyUser,
    action: PermissionAction.Read,
  },
  ReadCompanyPublicApiSettings: {
    key: CoreUserPermissionKey.Company,
    action: PermissionAction.ReadCompanyPublicApiSettings,
  },
  CreateCompanyUser: {
    key: CoreUserPermissionKey.CompanyUser,
    action: PermissionAction.Create,
  },
  InviteCompanyUser: {
    key: CoreUserPermissionKey.CompanyUser,
    action: PermissionAction.CreateInvite,
  },
} as const;

/**
 * @description COMPANY_ACCOUNT
 */
const CoreUserCompanyAccountPermissionsMap = {
  ReadCompanyAccount: {
    key: CoreUserPermissionKey.CompanyAccount,
    action: PermissionAction.Read,
  },
  CreateCompanyAccount: {
    key: CoreUserPermissionKey.CompanyAccount,
    action: PermissionAction.Create,
  },
  ReadCompanyAccountsBalances: {
    key: CoreUserPermissionKey.CompanyAccount,
    action: PermissionAction.ReadBalances,
  },
  ReadCompanyAccountSpend: {
    key: CoreUserPermissionKey.CompanyAccountSpend,
    action: PermissionAction.Read,
  },
} as const;

/**
 * @description EXTERNAL_ACCOUNT
 */
const CoreUserExternalAccountPermissionsMap = {
  ReadExternalAccount: {
    key: CoreUserPermissionKey.ExternalAccount,
    action: PermissionAction.Read,
  },
  CreateExternalAccount: {
    key: CoreUserPermissionKey.ExternalAccount,
    action: PermissionAction.Create,
  },
  ReadExternalConnection: {
    key: CoreUserPermissionKey.ExternalConnection,
    action: PermissionAction.Read,
  },
  CreateExternalConnection: {
    key: CoreUserPermissionKey.ExternalConnection,
    action: PermissionAction.CreateLinkToken,
  },
} as const;

/**
 * @description BUDGET
 */
const CoreUserBudgetPermissionsMap = {
  ReadBudget: {
    key: CoreUserPermissionKey.Budget,
    action: PermissionAction.Read,
  },
  CreateBudget: {
    key: CoreUserPermissionKey.Budget,
    action: PermissionAction.Create,
  },
  ReadBudgetUser: {
    key: CoreUserPermissionKey.BudgetUser,
    action: PermissionAction.Read,
  },
  CreateBudgetUser: {
    key: CoreUserPermissionKey.BudgetUser,
    action: PermissionAction.Create,
  },
} as const;

/**
 * @description CARD
 */
const CoreUserCardPermissionsMap = {
  ReadCard: {
    key: CoreUserPermissionKey.Card,
    action: PermissionAction.Read,
  },
  CreateCard: {
    key: CoreUserPermissionKey.Card,
    action: PermissionAction.Create,
  },
} as const;

/**
 * @description TRANSACTION
 */
const CoreUserTransactionPermissionsMap = {
  ReadTransaction: {
    key: CoreUserPermissionKey.Transaction,
    action: PermissionAction.Read,
  },
  CreateTransaction: {
    key: CoreUserPermissionKey.Transaction,
    action: PermissionAction.Create,
  },
  CreateInternalTransaction: {
    key: CoreUserPermissionKey.Transaction,
    action: PermissionAction.CreateTransactionsInternal,
  },
  CreatePaymentTransaction: {
    key: CoreUserPermissionKey.Transaction,
    action: PermissionAction.CreateTransactionsPayment,
  },
  ReadExternalTransaction: {
    key: CoreUserPermissionKey.ExternalTransaction,
    action: PermissionAction.Read,
  },
  CreateExternalTransaction: {
    key: CoreUserPermissionKey.ExternalTransaction,
    action: PermissionAction.Create,
  },
  ReadReport: {
    key: CoreUserPermissionKey.Report,
    action: PermissionAction.Read,
  },
} as const;

/**
 * @description INVOICE_COUNTERPARTY
 */
const CoreUserInvoiceAndCounterpartyPermissionsMap = {
  ReadCounterparty: {
    key: CoreUserPermissionKey.Counterparty,
    action: PermissionAction.Read,
  },
  ReadCounterpartyPaymentAccount: {
    key: CoreUserPermissionKey.CounterpartyPaymentAccount,
    action: PermissionAction.Read,
  },
  CreateCounterparty: {
    key: CoreUserPermissionKey.Counterparty,
    action: PermissionAction.Create,
  },
  ReadInvoice: {
    key: CoreUserPermissionKey.Invoice,
    action: PermissionAction.Read,
  },
  CreateInvoice: {
    key: CoreUserPermissionKey.Invoice,
    action: PermissionAction.Create,
  },
} as const;

/**
 * Это мапер толкьо для пермишенов и user/me
 */
export const CoreUserPermissionsMap = {
  ...CoreUserCompanyPermissionsMap,
  ...CoreUserCompanyAccountPermissionsMap,
  ...CoreUserExternalAccountPermissionsMap,
  ...CoreUserBudgetPermissionsMap,
  ...CoreUserCardPermissionsMap,
  ...CoreUserTransactionPermissionsMap,
  ...CoreUserInvoiceAndCounterpartyPermissionsMap,

  // OTHER
  ReadUser: {
    key: CoreUserPermissionKey.User,
    action: PermissionAction.Read,
  },
  CreateUser: {
    key: CoreUserPermissionKey.User,
    action: PermissionAction.Create,
  },
  ReadCurrencyExchangeRate: {
    key: CoreUserPermissionKey.CurrencyExchangeRate,
    action: PermissionAction.Read,
  },
} as const;

export const CorePhoneVerificationsMap = {
  [CorePhoneVerificationMethod.Sms]: {
    title: 'SMS',
    description: 'We’ve sent you a confirmation code via SMS.',
    retryButtonTitle: 'Click to resend',
    retryDescription: `Didn't receive the message?`,
  },
  [CorePhoneVerificationMethod.Whatsapp]: {
    title: 'WhatsApp',
    description: 'We’ve sent you a confirmation code via WhatsApp.',
    retryButtonTitle: 'Click to resend',
    retryDescription: `Didn't receive the message?`,
  },
  [CorePhoneVerificationMethod.Call]: {
    title: 'Phone Call',
    description: 'We will call you with a verification code.',
    retryButtonTitle: 'Try again',
    retryDescription: `Didn't receive a call?`,
  },
};

export const CoreOrganizationsSortTypesMap = [
  {
    label: 'Name',
    value: CoreOrganizationsSortType.Name,
    isDefault: true,
    activeLabelColor: UiColorVar.Secondary500,
  },
  {
    label: 'Balance',
    value: CoreOrganizationsSortType.Balance,
    isDefault: false,
    activeLabelColor: UiColorVar.Primary500,
  },
  {
    label: 'Spent',
    value: CoreOrganizationsSortType.Spend,
    isDefault: false,
    activeLabelColor: UiColorVar.Primary500,
  },
  {
    label: 'Income',
    value: CoreOrganizationsSortType.Income,
    isDefault: false,
    activeLabelColor: UiColorVar.Primary500,
  },
];
