import {
  CoreUserPermissionsMap,
  SETTINGS_BILLING_PAGE,
  SETTINGS_COMPANY_PAGE,
  SETTINGS_INDEX_PAGE,
  SETTINGS_PERSONAL_PAGE,
  SETTINGS_TOKENS_PAGE,
} from '@/data';
import { permissionsGuard } from '@/router/guards';

export default [
  {
    path: '/settings',
    name: SETTINGS_INDEX_PAGE.name,
    redirect: '/settings/personal',
    component: () => import('@/modules/settings/pages/SettingsIndexPage.vue'),
    beforeEnter: [permissionsGuard],
    meta: {
      auth: true,
      permissions: [CoreUserPermissionsMap.ReadUser],
    },
    children: [
      {
        path: 'personal',
        name: SETTINGS_PERSONAL_PAGE.name,
        component: () =>
          import('@/modules/settings/pages/SettingsPersonalPage.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          auth: true,
          /**
           * @todo добавить пермишен на update user
           */
          permissions: [CoreUserPermissionsMap.ReadUser],
        },
      },
      {
        path: 'company',
        name: SETTINGS_COMPANY_PAGE.name,
        component: () =>
          import('@/modules/settings/pages/SettingsCompanyPage.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          auth: true,
          /**
           * @todo добавить пермишен на update company
           */
          permissions: [CoreUserPermissionsMap.ReadCompany],
        },
      },
      {
        path: 'tokens',
        name: SETTINGS_TOKENS_PAGE.name,
        component: () =>
          import('@/modules/settings/pages/SettingsTokensPage.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          auth: true,
          permissions: [
            CoreUserPermissionsMap.ReadCompanyAccount,
            CoreUserPermissionsMap.ReadCompanyPublicApiSettings,
          ],
        },
      },
      {
        path: 'billing',
        name: SETTINGS_BILLING_PAGE.name,
        component: () =>
          import('@/modules/settings/pages/SettingsBillingPage.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          auth: true,
          permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
        },
      },
    ],
  },
];
