<script lang="ts" setup>
import { AppModalSize } from '@karta.io/app-components';
import {
  UiButtonSize,
  UiButtonTheme,
  UiColorVar,
  type UiFormInstance,
  type UiFormRules,
  UiTypography,
} from '@karta.io/ui-components';
import { isRequired } from '@karta.io/utils';

import { useCoreStore } from '@/stores';
import { createCompanyAccountStatement, readCompanyAccounts } from '@/api';
import { useAsyncSelectNextOptions, useErrorNotifyNew } from '@/composables';
import { LIMIT_DEFAULT, TIMEZONE_DEFAULT } from '@/data';

import { CompanyAccountStatementFormat, CompanyAccountType } from '@/enums';
import type {
  CompanyAccount,
  CompanyAccountStatementCreateRequest,
} from '@/interfaces';

interface Props {
  modelValue: boolean;
}

defineOptions({ name: 'CompanyAccountStatementModal' });

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
  'submit:success': [];
  destroy: [];
}>();

const cn = useClassName('company-account-statement-modal');
const coreStore = useCoreStore();
const { isMobile } = useBreakpoint();

const dayjs = useDayjs(TIMEZONE_DEFAULT);
const today = dayjs();

/**
 * Modal
 */
const isOpen = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
});

const closeModal = () => emit('update:modelValue', false);

const handleModalClose = () => {
  if (isLoading.value) return;
  closeModal();
};

/**
 * Form
 */
const FORM_ID = 'company-account-statement-modal-form';

const form = ref<UiFormInstance>();
const isLoading = ref(false);

interface DateOption {
  label: string;
  value: number;
}

const model = reactive<{
  companyAccount?: CompanyAccount;
  year?: DateOption;
  month?: DateOption;
  format: CompanyAccountStatementFormat;
}>({
  companyAccount: undefined,
  year: undefined,
  month: undefined,
  format: CompanyAccountStatementFormat.Pdf,
});

const isDisabled = computed(() => !model.companyAccount?.id);

const rules = computed<UiFormRules>(() => ({
  companyAccount: isRequired(),
  year: isRequired(),
  month: isRequired(),
  format: isRequired(),
}));

const buttonSize = computed(() =>
  isMobile.value ? UiButtonSize.Sm : UiButtonSize.Md,
);

const handleSubmit = async () => {
  if (!form.value) return;
  const { isValid } = await form.value.validate();
  if (!isValid) return;

  isLoading.value = true;

  try {
    const data: CompanyAccountStatementCreateRequest = {
      startDate: dayjs()
        .utc()
        .year(model.year!.value)
        .month(model.month!.value)
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: dayjs()
        .utc()
        .year(model.year!.value)
        .month(model.month!.value)
        .endOf('month')
        .format('YYYY-MM-DD'),
      format: model.format,
    };

    await createCompanyAccountStatement(model.companyAccount!.id, data);

    isLoading.value = false;
    closeModal();
    emit('submit:success');
  } catch (error) {
    isLoading.value = false;
    useErrorNotifyNew(error);
  }
};

/**
 * Company accounts select
 */
const {
  searchValue: companyAccountsSelectSearchValue,
  options: companyAccountsSelectOptions,
  isLoading: isCompanyAccountsSelectLoading,
  isFetching: isCompanyAccountsSelectFetching,
  hasNextPage: hasCompanyAccountsSelectNextPage,
  loadNextPage: loadCompanyAccountsSelectNextPage,
  updateSearchValue: updateCompanyAccountsSelectSearchValue,
} = useAsyncSelectNextOptions({
  queryKey: coreStore.queryKeyByCurrentCompanyId('companyAccountsForStatement'),
  pageLimit: LIMIT_DEFAULT,
  requestFn: readCompanyAccounts,
  params: {
    filters: {
      types: [CompanyAccountType.Primary],
    },
  },
});

/**
 * Date
 */
const createdAt = computed(() => dayjs(model.companyAccount?.createdAt));

const years = computed(() => {
  const createdAtYear = createdAt.value.year();
  const diffInYears = today.year() - createdAt.value.year();

  const years = [];

  for (let i = 0; i <= diffInYears; i++) {
    years.push({
      label: String(createdAtYear + i),
      value: createdAtYear + i,
    });
  }

  return years;
});

const months = computed(() => {
  const months = [];
  let totalMonths = 11;
  let firstMonth = 0;
  let currentMonth;

  if (model.year?.value === today.year()) {
    currentMonth = today.month();
    totalMonths = today.month();
  }

  if (model.year?.value === createdAt.value.year()) {
    firstMonth = createdAt.value.month();
  }

  for (let i = firstMonth; i <= totalMonths; i++) {
    const month = dayjs()
      .year(model.year?.value || today.year())
      .month(i)
      .format('MMMM');
    months.push({
      label: i === currentMonth ? `${month} (current)` : month,
      value: i,
    });
  }

  return months;
});

const handleYearChange = async (value: DateOption) => {
  model.year = value;
  await nextTick();

  model.month = months.value[months.value.length - 1];
};

watch(
  () => model.companyAccount,
  () => {
    model.year = years.value[years.value.length - 1];
    model.month = months.value[months.value.length - 1];
  },
);

watch(
  companyAccountsSelectOptions,
  newCompanyAccountsSelectOptions => {
    if (model.companyAccount) return;
    model.companyAccount = newCompanyAccountsSelectOptions[0];
  },
  { immediate: true },
);
</script>

<template>
  <AppModal
    v-model="isOpen"
    :size="AppModalSize.Xs"
    classModifier="statement"
    @destroy="emit('destroy')"
  >
    <AppModalContent :class="cn.b()" @click:close="handleModalClose">
      <template #header>
        <AppModalHeader>
          <template #title>
            <AppText
              :typography="UiTypography.TextLgSemibold"
              :color="UiColorVar.Secondary900"
            >
              Statement
            </AppText>
          </template>
        </AppModalHeader>
      </template>

      <div :class="cn.e('container')">
        <AppText :typography="UiTypography.TextMdRegular">
          Get your Karta account activity, including balances and transactions.
          Statement represents main account and all its sub-accounts. All time
          references are in UTC.
        </AppText>
      </div>

      <UiForm
        :id="FORM_ID"
        ref="form"
        :model="model"
        :rules="rules"
        @submit.prevent="handleSubmit"
      >
        <div :class="cn.e('container')">
          <UiFormItem
            label="Select primary account"
            prop="companyAccountId"
            :hasMessageContainer="false"
          >
            <CompanyAccountSelect
              v-model="model.companyAccount"
              :searchValue="companyAccountsSelectSearchValue"
              :options="companyAccountsSelectOptions"
              :hasNextPage="hasCompanyAccountsSelectNextPage"
              :isOptionsLoading="
                isCompanyAccountsSelectLoading ||
                isCompanyAccountsSelectFetching
              "
              :isTriggerLoading="isCompanyAccountsSelectLoading"
              @load:nextPage="loadCompanyAccountsSelectNextPage"
              @update:searchValue="updateCompanyAccountsSelectSearchValue"
            />
          </UiFormItem>
        </div>

        <div :class="cn.e('container')">
          <UiGrid :cols="{ xxs: 1, sm: 2 }" :spacingY="24" :spacingX="16">
            <UiFormItem label="Year" prop="year" :hasMessageContainer="false">
              <UiSelectNext
                :modelValue="model.year"
                :options="years"
                :dropdownZIndex="301"
                :disabled="isDisabled"
                @update:modelValue="handleYearChange"
              />
            </UiFormItem>

            <UiFormItem label="Month" prop="month" :hasMessageContainer="false">
              <UiSelectNext
                v-model="model.month"
                :options="months"
                :dropdownZIndex="301"
                :disabled="isDisabled"
              />
            </UiFormItem>
          </UiGrid>
        </div>

        <div :class="cn.e('container')">
          <UiFormItem label="Format" prop="format" :hasMessageContainer="false">
            <UiRadioGroup v-model="model.format" :disabled="isDisabled">
              <UiRadio
                :label="CompanyAccountStatementFormat.Pdf"
                :value="CompanyAccountStatementFormat.Pdf"
              />
              <UiRadio
                :label="CompanyAccountStatementFormat.Csv"
                :value="CompanyAccountStatementFormat.Csv"
              />
            </UiRadioGroup>
          </UiFormItem>

          <AppText
            :typography="UiTypography.TextXsRegular"
            :color="UiColorVar.Secondary500"
            style="margin-top: var(--spacing-02)"
          >
            {{
              model.format === CompanyAccountStatementFormat.Pdf
                ? 'If transactions exceed 1000, they will be uploaded in CSV format and titled in PDF.'
                : ''
            }}
          </AppText>
        </div>
      </UiForm>

      <template #footer>
        <div :class="cn.e('footer')">
          <UiButton
            :theme="UiButtonTheme.SecondaryLink"
            :size="buttonSize"
            :disabled="isLoading"
            @click="handleModalClose"
          >
            Cancel
          </UiButton>

          <UiButton
            type="submit"
            :form="FORM_ID"
            :size="buttonSize"
            :loading="isLoading"
            :disabled="isLoading || isDisabled"
          >
            Get statement
          </UiButton>
        </div>
      </template>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.company-account-statement-modal {
  .ui-radio-group {
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-08);

    .ui-radio {
      margin-bottom: 0;
    }
  }

  &__container {
    margin-bottom: var(--spacing-06);
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-08);
  }
}
</style>
