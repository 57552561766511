import { UiColor, UiIconValue } from '@karta.io/ui-components';
import type { AppFilterTagDatePreset } from '@karta.io/app-components';

import { getFilterDatePresets } from '@/data';

import {
  Currency,
  FilterDatePeriodName,
  FilterType,
  TransactionFeeType,
  TransactionStatus,
  TransactionStatusClassModifier,
  TransactionType,
} from '@/enums';
import type {
  FiltersConfig,
  TransactionExternalFilters,
  TransactionsFilters,
} from '@/interfaces';

export const TransactionTypesMap = {
  [TransactionType.OutboundAch]: {
    title: 'ACH payment',
    icon: UiIconValue.ArrowRight,
    isFilterShow: true,
    group: 'ACH',
  },
  [TransactionType.InboundAch]: {
    title: 'ACH deposit',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: true,
    group: 'ACH',
  },
  [TransactionType.PullAch]: {
    title: 'ACH pull',
    icon: UiIconValue.ArrowRight,
    isFilterShow: true,
    group: 'ACH',
  },
  [TransactionType.ReturnedAch]: {
    title: 'ACH returned',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: true,
    group: 'ACH',
  },
  [TransactionType.OutboundWire]: {
    title: 'Wire payment',
    icon: UiIconValue.ArrowRight,
    isFilterShow: true,
    group: 'Wire',
  },
  [TransactionType.InboundWire]: {
    title: 'Wire deposit',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: true,
    group: 'Wire',
  },
  [TransactionType.ReturnedWire]: {
    title: 'Wire returned',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: true,
    group: 'Wire',
  },
  [TransactionType.OutboundSwift]: {
    title: 'SWIFT payment',
    icon: UiIconValue.ArrowRight,
    isFilterShow: true,
    group: 'SWIFT',
  },
  [TransactionType.InboundSwift]: {
    title: 'SWIFT deposit',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: true,
    group: 'SWIFT',
  },
  [TransactionType.ReturnedSwift]: {
    title: 'SWIFT returned',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: true,
    group: 'SWIFT',
  },
  [TransactionType.CardPayment]: {
    title: 'Virtual card payment',
    icon: UiIconValue.CreditCard,
    isFilterShow: true,
    group: 'Virtual card',
  },
  [TransactionType.CardRefund]: {
    title: 'Virtual card refund',
    icon: UiIconValue.CreditCardSolid,
    isFilterShow: true,
    group: 'Virtual card',
  },
  [TransactionType.InboundKarta]: {
    title: 'Karta deposit',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: true,
    group: 'Karta',
  },
  [TransactionType.OutboundKarta]: {
    title: 'Karta transfer',
    icon: UiIconValue.ArrowRight,
    isFilterShow: true,
    group: 'Karta',
  },
  [TransactionType.KartaCharge]: {
    title: 'Virtual card issuing',
    icon: UiIconValue.ReceiptTax,
    isFilterShow: true,
    group: 'Karta charge',
  },
  [TransactionType.InternationalTransactionAttemptCharge]: {
    title: 'Cross-border attempt',
    icon: UiIconValue.ReceiptTax,
    isFilterShow: true,
    group: 'Karta charge',
  },
  [TransactionType.WrongOutboundPayment]: {
    title: 'Incorrect charge',
    icon: UiIconValue.ArrowRight,
    isFilterShow: true,
    group: 'Adjustment',
  },
  [TransactionType.CompensationTransaction]: {
    title: 'Reversal charge',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: true,
    group: 'Adjustment',
  },
  [TransactionType.InternalTransfer]: {
    title: 'Transfer between accounts',
    icon: UiIconValue.SwitchHorizontal,
    isFilterShow: true,
    group: 'none',
  },
  [TransactionType.InboundCrypto]: {
    title: 'Crypto deposit',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: true,
    group: 'none',
  },
  [TransactionType.InboundDispute]: {
    title: 'Dispute',
    icon: UiIconValue.ArrowLeft,
    isFilterShow: false,
    group: 'none',
  },
  [TransactionType.OutboundAdjustment]: {
    title: 'Refund of bank transfer',
    icon: UiIconValue.ArrowRight,
    isFilterShow: true,
    group: 'none',
  },
} as const;

export const TransactionStatusesMap = {
  [TransactionStatus.Settled]: {
    title: 'Settled',
    tagColor: UiColor.Success,
    classModify: TransactionStatusClassModifier.Settled,
  },
  [TransactionStatus.Pending]: {
    title: 'Pending',
    tagColor: UiColor.Secondary,
    classModify: TransactionStatusClassModifier.Pending,
  },
  [TransactionStatus.Declined]: {
    title: 'Declined',
    tagColor: UiColor.Error,
    classModify: TransactionStatusClassModifier.Failed,
  },
  [TransactionStatus.Reversed]: {
    title: 'Reversed',
    tagColor: UiColor.Warning,
    classModify: TransactionStatusClassModifier.Failed,
  },
} as const;

export const TransactionFeeTypesMap = {
  [TransactionFeeType.InternationalPayment]: {
    title: 'Cross-border fee',
  },
  [TransactionFeeType.CurrencyExchange]: {
    title: 'Foreign exchange',
  },
  [TransactionFeeType.InboundAch]: {
    title: 'ACH incoming fee',
  },
  [TransactionFeeType.OutboundAch]: {
    title: 'ACH payment fee',
  },
  [TransactionFeeType.InboundWire]: {
    title: 'Wire incoming fee',
  },
  [TransactionFeeType.OutboundWire]: {
    title: 'ACH payment fee',
  },
  [TransactionFeeType.InboundSwift]: {
    title: 'SWIFT incoming fee',
  },
  [TransactionFeeType.InvoicePaid]: {
    title: 'Fee per paid invoice',
  },
  [TransactionFeeType.InboundCrypto]: {
    title: 'Karta fee',
  },
  [TransactionFeeType.InboundCryptoNetwork]: {
    title: 'Network fee',
  },
  [TransactionFeeType.SmallDomesticTransaction]: {
    title: 'Domestic under $10.00 fee',
  },
  [TransactionFeeType.SmallInternationalTransaction]: {
    title: 'International under $30.00 fee',
  },
  default: {
    title: 'Fee',
  },
} as const;

export const TransactionsFiltersConfig: FiltersConfig<TransactionsFilters> = {
  companyAccountIds: {
    type: FilterType.Array,
  },
  userIds: {
    type: FilterType.Array,
  },
  budgetIds: {
    type: FilterType.Array,
  },
  counterpartyIds: {
    type: FilterType.Array,
  },
  counterpartyPaymentAccountIds: {
    type: FilterType.Array,
  },
  statuses: {
    type: FilterType.Array,
    possibleValues: Object.values(TransactionStatus),
  },
  types: {
    type: FilterType.Array,
    possibleValues: Object.values(TransactionType),
  },
  cardIds: {
    type: FilterType.Array,
  },
  withFeeOnly: {
    type: FilterType.String,
  },
  currencies: {
    type: FilterType.Array,
    possibleValues: Object.values(Currency),
  },
  periodName: {
    type: FilterType.String,
  },
  periodDates: {
    type: FilterType.Array,
  },
} as const;

export const TransactionExternalFiltersConfig: FiltersConfig<TransactionExternalFilters> =
  {
    periodName: {
      type: FilterType.String,
    },
    periodDates: {
      type: FilterType.Array,
    },
  } as const;

export const getTransactionsFilterDatePresets =
  (): AppFilterTagDatePreset[] => [
    ...getFilterDatePresets(),
    {
      start: null,
      end: null,
      listTitle: 'All time',
      clearable: true,
      triggerValueData: 'All time',
      periodName: FilterDatePeriodName.AllTime,
    },
  ];
