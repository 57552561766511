export enum TransactionType {
  CardPayment = 'CARD_PAYMENT',
  CardRefund = 'CARD_REFUND',
  InboundAch = 'INBOUND_ACH',
  OutboundAch = 'OUTBOUND_ACH',
  ReturnedAch = 'RETURNED_ACH',
  InboundWire = 'INBOUND_WIRE',
  OutboundWire = 'OUTBOUND_WIRE',
  ReturnedWire = 'RETURNED_WIRE',
  InboundSwift = 'INBOUND_SWIFT',
  OutboundSwift = 'OUTBOUND_SWIFT',
  ReturnedSwift = 'RETURNED_SWIFT',
  InboundKarta = 'INBOUND_KARTA',
  OutboundKarta = 'OUTBOUND_KARTA',
  InternalTransfer = 'INTERNAL_TRANSFER',
  KartaCharge = 'KARTA_CHARGE',
  InternationalTransactionAttemptCharge = 'INTERNATIONAL_TRANSACTION_ATTEMPT_CHARGE',
  PullAch = 'PULL_ACH',
  InboundCrypto = 'INBOUND_CRYPTO',
  InboundDispute = 'INBOUND_DISPUTE',
  OutboundAdjustment = 'OUTBOUND_ADJUSTMENT',
  WrongOutboundPayment = 'WRONG_OUTBOUND_PAYMENT',
  CompensationTransaction = 'COMPENSATION_TRANSACTION',
}

export enum TransactionChargeType {
  CardsIssuing = 'CARD_ISSUING',
}

export enum TransactionFeeType {
  InternationalPayment = 'INTERNATIONAL_PAYMENT',
  CurrencyExchange = 'CURRENCY_EXCHANGE',
  InboundAch = 'INBOUND_ACH',
  OutboundAch = 'OUTBOUND_ACH',
  InboundWire = 'INBOUND_WIRE',
  OutboundWire = 'OUTBOUND_WIRE',
  InboundSwift = 'INBOUND_SWIFT',
  InvoicePaid = 'INVOICE_PAID',
  InboundCrypto = 'INBOUND_CRYPTO',
  InboundCryptoNetwork = 'INBOUND_CRYPTO_NETWORK',
  SmallDomesticTransaction = 'SMALL_DOMESTIC_TRANSACTION',
  SmallInternationalTransaction = 'SMALL_INTERNATIONAL_TRANSACTION',
}

export enum TransactionStatus {
  Settled = 'SETTLED',
  Pending = 'AUTHORIZED',
  Declined = 'DECLINED',
  Reversed = 'REVERSED',
}

export enum TransactionExternalDirection {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING',
}

export enum TransactionExternalType {
  Online = 'online',
  InStore = 'in store',
  Other = 'other',
}

export enum TransactionStatusClassModifier {
  Pending = 'pending',
  Settled = 'settled',
  Failed = 'failed',
}
