import { UiColor, UiToastNotify } from '@karta.io/ui-components';
import { type ResponseError, formatErrorForNotify } from '@karta.io/utils';

import { CoreForbiddenReason } from '@/enums';

export function useErrorNotifyNew(error: ResponseError | Error | any): void {
  if (!error) return;

  if (
    error.status === 403 &&
    error.data?.reason === CoreForbiddenReason.AccessDenied
  ) {
    UiToastNotify({
      title: 'Error',
      message: "You don't have permission for this action",
      color: UiColor.Error,
    });

    return;
  }

  UiToastNotify({
    color: UiColor.Error,
    ...formatErrorForNotify(error),
  });
}
